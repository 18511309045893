import React from 'react';
import NextImage, { ImageLoaderProps } from 'next/image';
import classes from './Image.module.scss';
import clsx from 'clsx';

type ImageType = typeof NextImage;

function imageLoader({ src, width, quality }: ImageLoaderProps) {
  const url = src.match(/^\//) ? `${process.env.PUBLIC_URL || ''}${src}` : src;
  const options = [`w:${width}`, `q:${quality || 75}`].filter(Boolean);
  return `https://cdn.img.qmee.com/img/${options.join('/')}/plain/${url}@webp`;
}

function Image(props: React.ComponentProps<ImageType>) {
  return (
    <NextImage
      loader={process.env.NODE_ENV === 'development' ? undefined : imageLoader}
      {...props}
      className={clsx(classes.img, props.className)}
    />
  );
}
export default Image;
